import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import BlogDetails from "../../pages/blogs/blog-details/BlogDetails";
import Blogs from "../../pages/blogs/Blogs";
import ContactUs from "../../pages/contact-us/ContactUs";
import CeoLetter from "../../pages/ceoLetter/CeoLetter";
import NotFound from "../../pages/error/notFound/NotFound";
import Vacancies from "../../pages/vacancies/Vacancies";
import VacancyDetails from "../../pages/vacancies/vacancy-details/VacancyDetails";
import CompanyFilosofy from "../../pages/companyFilosofy/CompanyFilosofy";
import Goals from "../../pages/goals/Goals";
import Projects from "../../pages/projects/Projects";
import ProjectDetails from "../../pages/projects/project-details/ProjectDetails";
import Home from "../../pages/home/Home";
import OurServiceDetails from "../../pages/our-service-details/OurServiceDetails";
import SubServiceDetails from "../../pages/our-service-details/sub-service-details/SubServiceDetails";

const LangWrapper = () => {

    let { path } = useRouteMatch();

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <Home/>
                </Route>
                <Route path={`${path}/projects`} exact component={Projects} />
                <Route path={`${path}/project-details/:slug`} exact component={ProjectDetails} />
                <Route path={`${path}/our-service-details/:slug`} exact component={OurServiceDetails} />
                <Route path={`${path}/sub-service-details/:slug`} exact component={SubServiceDetails} />
                <Route path={`${path}/ceo-letter`} exact component={CeoLetter} />
                <Route path={`${path}/goals`} exact component={Goals} />
                <Route path={`${path}/company-filosofy`} exact component={CompanyFilosofy} />
                <Route path={`${path}/contact-us`} exact component={ContactUs} />
                <Route path={`${path}/blogs`} exact component={Blogs} />
                <Route path={`${path}/blog-details/:slug`} exact component={BlogDetails} />
                <Route path={`${path}/news-details/:slug`} exact component={BlogDetails} />
                <Route path={`${path}/vacancies`} exact component={Vacancies} />
                <Route path={`${path}/vacancy-details/:slug`} exact component={VacancyDetails} />
                <Route path={`${path}/internship-details/:slug`} exact component={VacancyDetails} />
                <Route path="*" component={NotFound}/>
                    {/* 404
                </Route> */}
            </Switch>
        </>
    )
}

export default LangWrapper
