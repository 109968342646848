import React from "react";
import { Link, useLocation } from "react-router-dom";
// import './Blogs.scss';

import chevronRightDark from '../../../../../assets/icons/chevron-right.svg';
// import Project from '../../../../assets/images/project_card.svg';

function ProjectCard(project) {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];

    return (
        <Link to={`/${lang}/project-details/${project.project.slug}`} className="project_card">
            <img className="project_card_img" src={'https://dev-cpanel.knexel.com/' + project.project.images[0]?.formats?.medium?.url} alt="" />
            <div className="project_card_info">
                <p className="project_card_info_head">{project.project.name}</p>
                <p className="project_card_info_desc">{project.project.shortDescription}</p>
                <div className="circle_projects">
                    <div className="chevron_circle_projects">
                        <img width="12" height="12" src={chevronRightDark} alt="" />
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ProjectCard