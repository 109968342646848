import React, { useState } from "react";
import './BlogDetails.scss';
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import BlogCard from "../blog-card/BlogCard";

import fb from '../../../../../assets/icons/facebook-circular-logo.svg';
import { useParams } from "react-router";

import ReactMarkdown from "react-markdown"
import Carousel from 'react-elastic-carousel';

const BLOGSNEWS = gql`
query GetBlogsNews($_newsBlogTypes: String, $_locale: String!,$_start: Int!,$_limit: Int!){
    blogsnews(where: {newsBlogTypes: $_newsBlogTypes, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        startDate
        slug,
        id,
        shortDesc,
        newsBlogTypes,
        pageMainContent{
            title,
            image {
                formats
              }
        }
    }
  }
`;

const BLOGSNEWS_DETAIL = gql`
query GetBlogsNews($slug: String!){
    blogsnews(where: {slug: $slug}) {
        startDate,
        newsBlogTypes,
        pageMainContent{
            title,
            description,
            image {
                formats
              }
        }
    }
  }
`;

function BlogDetails() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [newsBlogTypes, setNewsBlogTypes] = useState(loc.pathname.split('/')[2].split('-')[0]);
    let [blogDetails, setBlogDetails] = useState(null);

    let [breakPoints] = useState([
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 550, itemsToShow: 2, pagination: false }
    ]);

    let { slug } = useParams();
    const { detailsLoading, detailsError, detailsData } = useQuery(BLOGSNEWS_DETAIL, {
        variables: { slug },
        onCompleted: data => {
            console.log(data);
            setBlogDetails(data.blogsnews[0])
        }
    });

    const { loading, error, data } = useQuery(BLOGSNEWS, {
        variables: { _newsBlogTypes: newsBlogTypes, _locale: lang, _start: 0, _limit: 3 }
    });

    // if (loading) return <p>loading...</p>;
    // if (error) return <p>Error :(</p>;

    return (
        <div className="blog">
            <section id="blog">
                <div className="blog_content container">
                    <h2 className="blog_head">{blogDetails?.pageMainContent?.title}</h2>
                    <div className="date_share">
                        {blogDetails?.startDate ?
                            <p className="date">{blogDetails?.startDate}</p> : ''
                        }

                        {/* <div className="share">
                            <img className="fb_logo" src={fb} alt="" />
                            Paylaş
                        </div> */}
                    </div>
                    <div className="blog_slider">
                        <Carousel breakPoints={breakPoints}>
                            {blogDetails?.pageMainContent.image?.map((img, i) => (
                                <div key={img + i}>
                                    <img src={'https://dev-cpanel.knexel.com/' + img?.formats?.medium?.url} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <ReactMarkdown className="blog_text">
                        {blogDetails?.pageMainContent?.description}
                    </ReactMarkdown>


                    <div className="blog_similar">
                        <p className="blog_similar_head">
                            {newsBlogTypes === 'news' ?
                                'Oxşar xəbərlər' : 'Oxşar bloglar'
                            }
                        </p>
                        <div className="similar_blog_cards">
                            <div className="blogs_list">
                                {data?.blogsnews?.map((blog, i) => (
                                    <div className="new_card_wrapper" key={blog.slug + i}>
                                        <BlogCard blog={blog} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default BlogDetails