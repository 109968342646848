import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
import PageBanner from "../../../layout/page-banner/PageBanner";

import { useParams } from "react-router";

import './SubServiceDetails.scss';
import Appoint from "../../appoint/Appoint";

// import ChevronRight from "../../../../../assets/icons/chevron-right.svg"

const SUBSERVICEDETAILS = gql`
query GetServiceDetails($_locale: String!, $slug: String!){
    subservices(where: {_locale: $_locale, slug: $slug}) {
        name,
        pageInnerTitle,
        longDescription,
        ServicePros {
            title,
            description
        }
    }
  }
`;

function SubServiceDetails() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [ourServices, setOurServices] = useState(loc.pathname.split('/')[1]);

    let { slug } = useParams();

    const { loading, error, data } = useQuery(SUBSERVICEDETAILS, {
        variables: {
            _locale: lang,
            slug: slug
        }
    });

    return (
        <div>
            <PageBanner name={data?.subservices[0]?.name}
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="service container">
                <div className="service_heading">
                    <div className="services_jumbotron">
                        <h2 className="service_header">{data?.subservices[0]?.pageInnerTitle}</h2>
                        <ReactMarkdown className="services_description">
                            {data?.subservices[0]?.longDescription}
                        </ReactMarkdown>
                    </div>
                </div>
                <div className="service_advantages">
                    <h2 className="service_header adv_head">Xidmətin üstünlükləri:</h2>
                    <div className="service_advantages_list">

                        {data?.subservices[0]?.ServicePros?.map((servicePros, i) => (
                            <div className="service_advantages_card" key={'servicePros'+i}>
                                <h3 className="card_head">{servicePros?.title}</h3>
                                <ReactMarkdown className="card_desc">
                                    {servicePros?.description}
                                </ReactMarkdown>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Appoint/>
        </div>

    )
}

export default SubServiceDetails