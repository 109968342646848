import React, { useEffect, useRef, useState } from "react";
import "./Header.scss"
import logoDark from "../../../../assets/images/logo-dark.svg";
import logoWhite from "../../../../assets/images/logo.png";
import chevronUpDark from "../../../../assets/icons/chevron-up-dark.svg";
import burger_icon from "../../../../assets/icons/burger_icon.svg";

import ChevronUp from "../../../../assets/icons/chevron-up.svg"
import Close from "../../../../assets/icons/close.svg"
import facebook from "../../../../assets/icons/facebook.svg";
import instgramsvg from "../../../../assets/icons/instgramsvg.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";
import ChevronRightGrey from "../../../../assets/icons/chevron-right-grey.svg";

import {
    useQuery,
    gql
} from "@apollo/client";

import {
    Link,
    NavLink,
    useLocation
} from "react-router-dom";

const OURSERVICES = gql`
query GetOurServices($_locale: String!){
    ourservices(where: {_locale: $_locale}) {
        name,
        slug,
        subservices{
            name,
            slug
        }
    }
}
`;

function useOutsideAlerter(ref) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                ref.current.classList.remove("active");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const Header = () => {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];

    const [isAtHome, setIsAtHome] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeAccordionName, setActiveAccordionName] = useState('');
    let [ourServices, setOurServices] = useState(null);

    const wrapperRef = useRef(null);
    const wrapperRef1 = useRef(null);
    useOutsideAlerter(wrapperRef);
    useOutsideAlerter(wrapperRef1);

    const toggleDropdown = (e) => {
        e.target.classList.contains('active') ? e.target.classList.remove('active') : e.target.classList.add('active');
    }

    useQuery(OURSERVICES, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            setOurServices(data);
        }
    });

    useEffect(() => {
        setIsAtHome(loc.pathname === '/az' || loc.pathname === '/en' || loc.pathname === '/ru');
        document.querySelectorAll('.navbar_menu_item.menu_dropdown').forEach(elem => {
            elem.classList.remove('active')
        })
    }, [loc]);

    const toggleAccordion = (name) => {
        setActiveAccordionName(activeAccordionName === name? '' : name);
    }

    return (
        <div className={`${isAtHome ? "home_navbar" : "light_navbar"}`}>
            <div className="container h-100">
                <div className="navbar_top_bar ">
                    <div className="burger_menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <img src={burger_icon} alt="" />
                    </div>
                    <Link to="/" className="home_logo">
                        <img src={logoDark} alt="" />
                        <img width="200" src={logoWhite} alt="" />
                    </Link>
                    <div className="navbar_main_menu">
                        <ul className="navbar_menu_items">
                            <li ref={wrapperRef} onClick={(e) => toggleDropdown(e)} className="navbar_menu_item menu_dropdown">Xidmətlər
                                <img className="menu_item_chevron" src={chevronUpDark} alt="" />
                                <div className="menu_opened">
                                    <ul className="menu_opened_content">
                                        {ourServices?.ourservices?.map((service, i) => (
                                            <li className="menu_nav has-sub" key={service.slug + i}>
                                                <NavLink to={`/${lang}/our-service-details/${service.slug}`} activeClassName="selected_menu">{service.name}
                                                    <img className="chevron" src={ChevronRightGrey} alt="" />
                                                </NavLink>
                                                <div className="multi_menu">
                                                    <ul className="menu_opened_content">
                                                        {service.subservices?.map((subService, i) => (
                                                            <li className="multi_menu_nav" key={subService.slug + i}>
                                                                <NavLink to={`/${lang}/sub-service-details/${subService.slug}`} activeClassName="selected_menu">
                                                                    {subService.name}
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </li>
                            <li ref={wrapperRef1} onClick={(e) => toggleDropdown(e)} className="navbar_menu_item menu_dropdown">Haqqımızda
                                <img className="menu_item_chevron" src={chevronUpDark} alt="" />
                                <div className="menu_opened">
                                    <ul className="menu_opened_content">
                                        <li className="menu_nav">
                                            <NavLink to={`/${lang}/company-filosofy`} activeClassName="selected_menu">Şirkətin Fəlsəfəsi</NavLink>
                                        </li>
                                        <li className="menu_nav">
                                            <NavLink to={`/${lang}/goals`} activeClassName="selected_menu">Məqsəd və Dəyərlərimiz</NavLink>
                                        </li>
                                        <li className="menu_nav">
                                            <NavLink to={`/${lang}/ceo-letter`} activeClassName="selected_menu">Şirkət rəhbərinin müraciəti</NavLink>
                                        </li>
                                        {/* <li className="menu_nav">
                                            <Link to={`/${lang}/goals`}>Uğurlar və Sertifikatlar</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </li>
                            <li className="navbar_menu_item">
                                <Link  to={`/${lang}/projects`}>Layihələr</Link>
                            </li>
                            {/*<li className="navbar_menu_item">*/}
                            {/*    <Link  to={`/${lang}/blogs`}>Bloq</Link>*/}
                            {/*</li>*/}
                            <li className="navbar_menu_item">
                                <Link  to={`/${lang}/contact-us`}>Əlaqə</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar_language">
                        <a className="menu_item menu_dropdown">AZ
                            <img className="menu_item_chevron" src={chevronUpDark} alt="" /></a>
                    </div>
                </div>
            </div>
            <div className={`burger_menu_opened ${isMenuOpen ? "active" : ""}`}>
                <div className="burger_menu_items">
                    <ul className="burger_menu_list">
                        <button className="close_menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <img src={Close} alt="" />
                        </button>
                        <li className={`burger_menu_item burger_dropdown ${activeAccordionName === "services" ? "burger_dropdown_open" : ""}`}>
                            <div onClick={()=>toggleAccordion('services')} className="flex">
                                <span>Xidmətlər</span>
                                <img className="menu_item_chevron_down" src={ChevronUp} alt="" />
                            </div>
                            <ul className="opened_menu_items">

                                {ourServices?.ourservices?.map((service, i) => (
                                    <li key={service.slug + i} onClick={() => setIsMenuOpen(false)}>
                                        <NavLink to={`/${lang}/our-service-details/${service.slug}`}>{service.name}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        <li className={`burger_menu_item burger_dropdown ${activeAccordionName === "aboutUs" ? "burger_dropdown_open" : ""}`}>
                            <div onClick={()=>toggleAccordion('aboutUs')} className="flex">
                                <span>Haqqımızda</span>
                                <img className="menu_item_chevron_down" src={ChevronUp} alt="" />
                            </div>
                            <ul className="opened_menu_items">
                                <li onClick={() => setIsMenuOpen(false)}>
                                    <NavLink to={`/${lang}/company-filosofy`}>Şirkətin Fəlsəfəsi</NavLink>
                                </li>
                                <li onClick={() => setIsMenuOpen(false)}>
                                    <NavLink to={`/${lang}/goals`}>Məqsəd və Dəyərlərimiz</NavLink>
                                </li>
                                <li onClick={() => setIsMenuOpen(false)}>
                                    <NavLink to={`/${lang}/ceo-letter`}>Şirkət rəhbərinin müraciəti</NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="burger_menu_item burger_dropdown" onClick={() => setIsMenuOpen(false)}>
                            <Link className="menu_route" to={`/${lang}/projects`}>Layihələr</Link>
                        </li>
                        <li className="burger_menu_item burger_dropdown" onClick={() => setIsMenuOpen(false)}>
                            <Link className="menu_route" to={`/${lang}/blogs`}>Bloq</Link>
                        </li>
                        <li className="burger_menu_item burger_dropdown" onClick={() => setIsMenuOpen(false)}>
                            <Link className="menu_route" to={`/${lang}/contact-us`}>Əlaqə</Link>
                        </li>
                    </ul>
                </div>
                <div className="burger_socials">
                    <a href="https://react-hook-form.com/get-started/" target="_blank" rel="noopener noreferrer">
                        <img className="social_icons" src={facebook} alt="" />
                    </a>
                    <a href="https://react-hook-form.com/get-started/" target="_blank" rel="noopener noreferrer">
                        <img className="social_icons" src={instgramsvg} alt="" />
                    </a>
                    <a href="https://react-hook-form.com/get-started/" target="_blank" rel="noopener noreferrer">
                        <img className="social_icons" src={linkedin} alt="" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header
