import React, { useState } from "react";
import {
    Link
} from "react-router-dom";

import './ContactUs.scss';

import location from '../../../../assets/icons/location.svg';
import mail from '../../../../assets/icons/mail.svg';
import call from '../../../../assets/icons/call.svg';
import clock2 from '../../../../assets/icons/clock2.svg';
import BlueCheckCircle from '../../../../assets/icons/blue-check-circle.svg';

import { useForm } from "react-hook-form";
import {
    useLazyQuery,
    gql,
    useMutation
} from "@apollo/client";
import PageBanner from "../../layout/page-banner/PageBanner";

const CREATECONTACT = gql`
mutation CreateContact ($fullname: String!, $email: String!, $phone: String!, $status: ENUM_CONTACT_STATUS!, $message: String!) {
    createContact(input:
        { data: {
            fullname: $fullname,
            email: $email,
            phone: $phone,
            status: $status,
            message: $message
        }}) {
            contact {
                id
            }
        }
      }
`;

function ContactUs() {
    const [formSubmitCond, setFormSubmitCond] = useState(false);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    const [onMutationSubmit, { loading, error, data }] = useMutation(CREATECONTACT, {
        onCompleted: data => {
            reset();
            setFormSubmitCond(true);
            setTimeout(() => {
                setFormSubmitCond(false);
            }, 2000);
        }
    });

    const onFormSubmit = (data) => {
        onMutationSubmit({
            variables: {
                fullname: data.fullname,
                email: data.email,
                phone: data.phone,
                status: data.status,
                message: data.message
            }
        })
    }
    return (
        <div>
            <PageBanner name="BİZİMLƏ ƏLAQƏ" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="contact container">
                <div className="contact_content">
                    <div className="contact_options">
                        <h2 className="contact_head">Əlaqə vasitələri</h2>
                        <div className="options_content">
                            <div className="option_item">
                                <img className="option_icon" src={location} alt="" />
                                <p className="option_name">H.Əliyev Pr. 125, Bakı</p>
                            </div>
                            <div className="option_item">
                                <img className="option_icon" src={mail} alt="" />
                                <p className="option_name">office@knexel.com</p>
                            </div>
                            <div className="option_item">
                                <img className="option_icon" src={call} alt="" />
                                <p className="option_name">+994123105300</p>
                            </div>
                            <div className="option_item">
                                <img className="option_icon" src={clock2} alt="" />
                                <p className="option_name">Bazar ertəsi - Cümə 09:00 - 18:00</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact_form">
                        <h2 className="contact_head">Bizə yazın</h2>
                        <form className="form" onSubmit={handleSubmit(onFormSubmit)}>
                            <div className="name_mail">
                                <input className={`half_input ${errors.fullname ? "error" : ""}`} type="text" placeholder="Ad, Soyad" {...register("fullname", {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    }
                                })} />
                                <input className={`half_input ${errors.email ? "error" : ""}`} type="text" placeholder="aliyev.nihad@gami"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: 'Required'
                                        },
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                            message: 'Invalid Email'
                                        }
                                    })} />
                            </div>
                            <div className="name_mail">
                                <input className={`half_input ${errors.phone ? "error" : ""}`} type="text" placeholder="Telefon" {...register("phone", {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    }
                                })} />
                                <div className="half_input status">
                                    <span className="status_name">Status</span>
                                    <div className="line"></div>
                                    <div className="radioboxes">
                                        <div className="radio__container">
                                            <div className="radio-inline">
                                                <input className="radio" id="awesome-item-1" name="radio" type="radio" value='complaint' checked {...register("status")} />
                                                <label className="radio__label" htmlFor="awesome-item-1">İrad</label>
                                            </div>
                                            <div className="radio-inline">
                                                <input className="radio" id="awesome-item-2" name="radio" type="radio" value='suggestion' {...register("status")} />
                                                <label className="radio__label" htmlFor="awesome-item-2">Təklif</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="">
                                <textarea className="textarea" cols="30" rows="10" placeholder="Mesajınız" {...register("message")}></textarea>
                            </div>
                            <div className="contact-success">
                                <div className="f_submit">
                                    <button className="action_button form_submit">Send</button>
                                </div>
                                {formSubmitCond ?
                                    <div className="suscces-msg">
                                        <img src={BlueCheckCircle} />
                                        <p>Mesajınız uğurla göndərildi!</p>
                                    </div>
                                    : ''
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
