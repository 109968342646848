import './App.scss';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import Header from './app/components/layout/header/Header';
import Footer from './app/components/layout/footer/Footer';
import LangWrapper from './app/components/layout/lang-wrapper/LangWrapper';
import ScrollToTop from './app/components/shared/ScrollToTop';
import { Fragment } from 'react';

function App() {
  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <Header />
        <Switch>

          <Route exact path="/" >
            <Redirect to="/az" />
          </Route>

          <Route path="/:lng(az|en|ru)?">
            <LangWrapper />
          </Route>

        </Switch>
        <Footer />
      </Fragment>
    </Router>
  );
}

export default App;
