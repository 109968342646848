import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";

import './Home.scss';
import ChevronRight from "../../../../assets/icons/chevron-right.svg"
import ChevronRightArrow from "../../../../assets/icons/chevron_right_arrow.svg"

import Carousel from 'react-elastic-carousel';
import ProjectCard from "../projects/project-card/ProjectCard";
import BlogCard from "../blogs/blog-card/BlogCard";
import Appoint from "../appoint/Appoint";

const OURSERVICES = gql`
query GetOurServices($_locale: String!){
    ourservices(where: {_locale: $_locale}) {
        name
        slug,
        id,
        shortDescription
    }
  }
`;

const HOMEPAGE = gql`
query GetHomePage($_locale: String!){
    homepage(locale: $_locale){
        successfullProjectCount,
        companyExperienceCount,
        serviceCount,
        workerCount,
        bannerTitle,
        bannerDescription,
        bannerImage {
            url
        },
        companyStatisticsBakground{
            url
        }
        serviceSectionTitle,
        serviceSectionDescription,
        yourPartnerSectionTitle,
        yourPartnerSectionDescription,
        yourPartnerSectionVideoEmbedLink,
        projectsSectionTitle,
        projectsSectionDescription,
        parallaxSectionTitle,
        parallaxSectionDescription,
        parallaxSectionBackground{
            url
        },
        newsSectionTitle,
        newsSectionDescription,
        ourPartnersSectionTitle,
        ourPartnersSectionDescription
    }
  }
`;

const PROJECTS = gql`
query GetProjects($_type: String, $_locale: String!,$_start: Int!,$_limit: Int!){
    projects(where: {type: $_type, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        name
        slug,
        id,
        shortDescription,
        images {
            formats
        }
    }
  }
`;

const BLOGSNEWS = gql`
query GetBlogsNews($_newsBlogTypes: String, $_locale: String!,$_start: Int!,$_limit: Int!){
    blogsnews(where: {newsBlogTypes: $_newsBlogTypes, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        startDate
        slug,
        id,
        shortDesc,
        newsBlogTypes,
        pageMainContent{
            title,
            image {
                formats
            }
        }
    }
  }
`;

const PARTNERS = gql`
query GetPartners{
    partners {
        url
        logo {
            url
        }
    }
  }
`;




function Home() {
   
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [ourServices, setOurServices] = useState(null);
    let [homePage, setHomePage] = useState(null);
    let [projects, setProjects] = useState(null);
    let [blogNews, setBlogNews] = useState(null);
    let [partners, setPartners] = useState(null);
    let [breakPoints] = useState([
        { width: 1, itemsToShow: 1, pagination: false },
        { width: 550, itemsToShow: 2, pagination: false },
        { width: 991, itemsToShow: 3, pagination: false }
    ]);

    let [breakPoints5] = useState([
        { width: 1, itemsToShow: 2, pagination: false },
        { width: 550, itemsToShow: 3, pagination: false },
        { width: 991, itemsToShow: 4, pagination: false },
        { width: 1024, itemsToShow: 5, pagination: false }
    ]);
    

    // const breakPoints = [
    //     { width: 667, itemsToShow: 1 },
    //     { width: 1024, itemsToShow: 3 },
    // ];

    useQuery(OURSERVICES, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            setOurServices(data);
        }
    });

    const { loading, error, data } = useQuery(HOMEPAGE, {
        variables: {
            _locale: lang
        },
        onCompleted(data) {
            setHomePage(data.homepage);
        }
    });

    useQuery(PROJECTS, {
        variables: {
            _locale: lang,
            _start: 0,
            _limit: 12
        },
        onCompleted(data) {
            setProjects(data.projects);
        }
    });

    useQuery(BLOGSNEWS, {
        variables: {
            _locale: lang,
            _start: 0,
            _limit: 12
        },
        onCompleted(data) {
            setBlogNews(data.blogsnews);
        }
    });

    useQuery(PARTNERS, {
        onCompleted(data) {
            setPartners(data.partners);
        }
    });



    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <div className="home">
            <section id="banner">
                {/* <div className="home_banner" style=" background: url('../../assets/images/banner.svg') no-repeat center;"> */}
                <div className="home_banner" style={{ backgroundImage: `url(https://dev-cpanel.knexel.com${homePage?.bannerImage?.url})` }}>
                    <div className="container h-100">
                        <div className="home_jumbotron">
                            <div className="home_jumbotron_content container">
                                <div className="jumbotron_action_part">
                                    <h1 className="jumbotron_heading">
                                        {homePage?.bannerTitle}
                                    </h1>
                                    <p className="jumbotron_p">
                                        {homePage?.bannerDescription}
                                    </p>
                                    <Link to={`/${lang}/contact-us`} className="action_button home_action">
                                        Görüş al
                                    </Link>
                                </div>
                                <div className="jumbotron_info_part">
                                    {/* <div className="info_content" style="background: url('../../assets/images/info_bg.svg') no-repeat;"> */}
                                    {/* style={{ backgroundImage: 'https://dev-cpanel.knexel.com' + homePage?.bannerImage?.formats?.medium?.url }} */}
                                    <div className="info_content" style={{ backgroundImage: `url(https://dev-cpanel.knexel.com${homePage?.companyStatisticsBakground?.url})` }}>
                                        <div className="content_top">
                                            <div className="top_item">
                                                <h2 className="item_head">{homePage?.companyExperienceCount}+</h2>
                                                <p className="item_desc">İl təcrübə</p>
                                            </div>
                                            <div className="top_item">
                                                <h2 className="item_head">{homePage?.successfullProjectCount}+</h2>
                                                <p className="item_desc">uğurlu layİhə</p>
                                            </div>
                                        </div>
                                        <div className="content_bottom">
                                            <div className="bottom_item">
                                                <h2 className="item_head">{homePage?.serviceCount}+</h2>
                                                <p className="item_desc">təklİf edİlən
                                                    xİdmət</p>
                                            </div>
                                            <div className="bottom_item">
                                                <h2 className="item_head">{homePage?.workerCount}+</h2>
                                                <p className="item_desc">komanda üzvü</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="services">
                {/* <div className="services" style=" background: url('../../assets/images/k_bg.svg') no-repeat left;"> */}
                <div className="services">
                    <div className="container services_content">
                        <div className="services_desc">
                            <h2 className="section_heading">
                                {homePage?.serviceSectionTitle}
                            </h2>
                            <p className="services_p">{homePage?.serviceSectionDescription}</p>
                        </div>
                        <div className="services_items">
                            <div className="services_items_top">
                                {ourServices?.ourservices?.map((service, i) => (
                                    <Link to={`/${lang}/our-service-details/${service.slug}`} className="digital_consulting" key={service.slug + i}>
                                        <p className="home_col_head">{service.name}</p>
                                        <p className="col_desc">{service.shortDescription}</p>
                                        <div className="circle">
                                            <div className="chevron_circle">
                                                <img width="12" height="12" src={ChevronRight} alt="" />
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section id="video">
                <div className="video container">
                    <h2 className="section_heading tech_head">{homePage?.yourPartnerSectionTitle}</h2>
                    <p className="section_p tech_p">{homePage?.yourPartnerSectionDescription}</p>
                    <iframe className="video_frame" width="1190" height="669" src={homePage?.yourPartnerSectionVideoEmbedLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </section> */}
            <section id="projects">
                <div className="projects container">
                    <h2 className="section_heading">{homePage?.projectsSectionTitle}</h2>
                    <p className="section_p">{homePage?.projectsSectionDescription}</p>
                    <div className="all">
                        <Link to={`/${lang}/projects`} className="all_projects">Bütün layihələr <img className="right_arrow" src={ChevronRightArrow} alt="" /></Link>
                        <div className="navigate">
                            <img className="navigate_left" src="assets/icons/navigate_left.svg" alt="" />
                            <img src="assets/icons/navigate_right.svg" alt="" />
                        </div>
                    </div>
                    <Carousel breakPoints={breakPoints} className="projects_cards">
                        {projects?.map((project, i) => (
                            <ProjectCard key={project.slug + i} project={project} />
                        ))}
                    </Carousel>
                </div>
            </section>
            <section id="parallax">
                {/* <div className="parallax" style="background-image: url('../../assets/images/parallax.jpg');"> */}
                <div className="parallax" style={{ backgroundImage: `url(https://dev-cpanel.knexel.com${homePage?.parallaxSectionBackground?.url})` }}>
                    <div className="container parallax_content">
                        <h2 className="parallax_head">{homePage?.parallaxSectionTitle}</h2>
                        <p className="parallax_p">{homePage?.parallaxSectionDescription}</p>
                        <Link to={`/${lang}/contact-us`} className="button_more">Daha ətraflı</Link>
                    </div>

                </div>
            </section>
            {/*<section id="news">*/}
            {/*    <div className="news container">*/}
            {/*        <h2 className="section_heading tech_head">{homePage?.newsSectionTitle}</h2>*/}
            {/*        <p className="section_p tech_p">{homePage?.newsSectionDescription}</p>*/}
            {/*        <Carousel breakPoints={breakPoints}>*/}
            {/*            {blogNews?.map((blog, i) => (*/}
            {/*                <BlogCard key={blog.slug + i} blog={blog} />*/}
            {/*            ))}*/}
            {/*        </Carousel>*/}
            {/*        <Link to={`/${lang}/blogs`} className="action_button ">Bütün xəbərlər</Link>*/}
            {/*    </div>*/}
            {/*</section>*/}
            
            <section id="partners">
                <div className="partners container">
                    <h2 className="section_heading tech_head">{homePage?.ourPartnersSectionTitle}</h2>
                    <p className="section_p tech_p">{homePage?.ourPartnersSectionDescription}</p>
                    <Carousel breakPoints={breakPoints5}>
                        {partners?.map((partner, i) => (
                            <a href={partner?.url} target="_blank" key={'partner-' + i}>
                                <img src={'https://dev-cpanel.knexel.com' + partner?.logo?.url} />
                            </a>
                        ))}
                    </Carousel>
                </div>
            </section>
            <Appoint/>
        </div>

    )
}

export default Home
