import React, { useEffect, useState } from "react";
import './Projects.scss';
import { Link, useLocation } from "react-router-dom";
import chevronUpDark from '../../../../assets/icons/chevron-up-dark.svg';
// import Project from '../../../../assets/images/project_card.svg';
import {
    useQuery,
    useLazyQuery,
    gql
} from "@apollo/client";
import axios from "axios";
// import BlogCard from "./blog-card/BlogCard";
import PageBanner from "../../layout/page-banner/PageBanner";
import ProjectCard from "./project-card/ProjectCard";

const PROJECTS = gql`
query GetProjects($_type: String, $_locale: String!,$_start: Int!,$_limit: Int!){
    projects(where: {type: $_type, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        name
        slug,
        id,
        shortDescription,
        images {
            formats
        }
    }
  }
`;

let totalBlogCount = 0;
let count = 0;
function Projects() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [activeTab, setActiveTab] = useState('all');
    let [activeTabText, setActiveTabText] = useState('Hamısı');
    let [isMobileTabOpen, setIsMobileTabOpen] = useState(false);
    let [projectList, setProjectList] = useState([]);
    let [queryParams, setQueryParams] = useState(null);

    const [getVacancies, { loading, error, data }] = useLazyQuery(PROJECTS, {
        variables: queryParams,
        onCompleted: data => {
            count += 20;
            setProjectList([...projectList, ...data.projects])
        }
    });

    useEffect(() => {
        return () => {
            totalBlogCount = 0;
        };
    }, []);

    useEffect(() => {
        totalBlogCount = 0;
        if (totalBlogCount === 0) {
            const itemCountQuery = activeTab !== 'all' ?
                `https://dev-cpanel.knexel.com/projects/count?type=${activeTab}` :
                `https://dev-cpanel.knexel.com/projects/count`
            axios(itemCountQuery).then(resp => {
                totalBlogCount = resp.data;
                setProjectList([]);
                count = 0;
                seeMore();
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if (queryParams?._locale) {
            getVacancies();
        }
    }, [queryParams]);

    if (error) return <p>Error :(</p>;

    const seeMore = () => {
        let paramsData = {
            _locale: lang,
            _start: count,
            _limit: 20
        }
        if (activeTab !== 'all') {
            paramsData["_type"] = activeTab;
        }
        setQueryParams({ ...paramsData });

    }

    const handleTabChange = (tab, isFromMobile = false) => {
        setActiveTab(tab);

        if (isFromMobile) {
            setIsMobileTabOpen(false);
            setActiveTabText(isFromMobile);
        }
    }

    return (
        <div className="blogs">
            <PageBanner name="LAYİHƏLƏR"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="projects container">
                <div className="blogs_tab">
                    <button onClick={() => handleTabChange('all')} className={`tab_item ${activeTab === 'all' ? "selected_tab" : ""}`}>
                        Hamısı
                    </button>
                    <div className="tab_divider"></div>
                    <button onClick={() => handleTabChange('website')} className={`tab_item ${activeTab === 'website' ? "selected_tab" : ""}`}>Websayt</button>
                    <div className="tab_divider"></div>
                    <button onClick={() => handleTabChange('itHelp')} className={`tab_item ${activeTab === 'itHelp' ? "selected_tab" : ""}`}>IT Help</button>
                    <div className="tab_divider"></div>
                    <button onClick={() => handleTabChange('consulting')} className={`tab_item ${activeTab === 'consulting' ? "selected_tab" : ""}`}>Consulting</button>
                </div>
                <div className="blogs_tab_mobile">
                    <button className="select_tab" onClick={() => setIsMobileTabOpen(!isMobileTabOpen)}>
                        <p className="select_name">{activeTabText}</p>
                        <img className="menu_item_chevron" src={chevronUpDark} alt="" />
                    </button>
                    <div className={`tab_options ${isMobileTabOpen ? 'active' : ''}`}>
                        <button onClick={() => handleTabChange('all', 'Hamısı')}>Hamısı</button>
                        <button onClick={() => handleTabChange('website', 'Websayt')}>Websayt</button>
                        <button onClick={() => handleTabChange('itHelp', 'IT Help')}>IT Help</button>
                        <button onClick={() => handleTabChange('consulting', 'Consulting')}>Consulting</button>
                    </div>
                </div>
                <div className="projects_cards">
                    {projectList?.map((project, i) => (
                        <ProjectCard key={project.slug + i} project={project} />
                    ))}
                </div>

                {projectList.length < totalBlogCount ?
                    <button className="action_button more" onClick={seeMore}>Daha çox</button>
                    : ''
                }
            </div>
        </div>
    )
}

export default Projects