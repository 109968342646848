import React, { useState } from "react";
import PageBanner from "../../../layout/page-banner/PageBanner";
import './ProjectDetails.scss';
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import { useParams } from "react-router";
import ReactMarkdown from "react-markdown"
import Carousel from 'react-elastic-carousel';

import check from "../../../../../assets/icons/check.svg";
import calendar from "../../../../../assets/icons/calendar.svg";
import multiUser from "../../../../../assets/icons/multiple-users-silhouette.svg";
import Appoint from "../../appoint/Appoint";

const PROJECT_DETAIL = gql`
query GetProjects($slug: String!){
    projects(where: {slug: $slug}) {
        name
        slug,
        id,
        longDescription,
        workerCount,
        processPeriod,
        processedWorks {
            name,
        }
        images {
            formats
        }
    }
  }
`;

function ProjectDetails() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [projectDetails, setProjectDetails] = useState(null);

    let { slug } = useParams();
    const { detailsLoading, detailsError, detailsData } = useQuery(PROJECT_DETAIL, {
        variables: { slug },
        onCompleted: data => {
            setProjectDetails(data.projects[0])
        }
    });

    return (
        <div>
            <PageBanner name={projectDetails?.name} description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="project container">
                <div className="project_jumbotron">
                    <h2 className="project_head">LAYİHƏ TƏSVİRİ</h2>
                    <ReactMarkdown className="project_desc">
                        {projectDetails?.longDescription}
                    </ReactMarkdown>
                </div>
                <div className="project_short_info">
                    <div className="project_info_item team_members">
                        <img className="project_info_item_icon" src={multiUser} alt="" />
                        <p>Komanda sayı: {projectDetails?.workerCount}</p>
                    </div>
                    <div className="project_info_item">
                        <img className="project_info_item_icon" src={calendar} alt="" />
                        <p>İcra müddəti: {projectDetails?.processPeriod}</p>
                    </div>
                </div>
                <div className="projects_done_works">
                    <h2 className="project_head">GÖRÜLMÜŞ İŞ</h2>
                    <div className="projects_done_works_list">

                        {projectDetails?.processedWorks?.map((processedWork, i) => (
                            <div className="work_item">
                                <img className="work_item_icon" src={check} alt="" />
                                <p>{processedWork?.name}</p>
                            </div>
                        ))}

                    </div>
                </div>
                {/* <div className="project_gallery">
                    <h2 className="project_head">İCRA PROSESİNDƏN GÖRÜNTÜLƏR</h2>
                    <Carousel itemsToShow={3}>
                        {projectDetails?.images?.map((img, i) => (
                            <div key={img + i}>
                                <img src={'https://dev-cpanel.knexel.com/' + img?.formats?.medium?.url} />
                            </div>
                        ))}
                    </Carousel>
                </div> */}
            </div>
            <Appoint />
        </div>
    )
}

export default ProjectDetails