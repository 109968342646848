import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
import PageBanner from "../../layout/page-banner/PageBanner";

import './CeoLetter.scss';

const CEOLETTER = gql`
query GetCeoLetter{
    ceoletter {
        title,
        description,
        signature,
        position,
        ceoImg{
            formats
        }
    }
  }
`;

function CeoLetter() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);

    const { loading, error, data } = useQuery(CEOLETTER);

    return (
        <div>
            <PageBanner name="ŞİRKƏT RƏHBƏRİNİN MÜRACİƏTİ"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="ceo container">
                <div className="ceo_media">
                    <img className="ceo_img" src={'https://dev-cpanel.knexel.com/' + data?.ceoletter?.ceoImg?.formats?.medium?.url} alt="" />
                </div>
                <div className="ceo_message">
                    <h3 className="ceo_message_head">{data?.ceoletter?.title}</h3>
                    <ReactMarkdown className="ceo_message_desc">
                        {data?.ceoletter?.description}
                    </ReactMarkdown>
                    <div className="ceo_cp">
                        <p className="ceo_name">{data?.ceoletter?.signature}</p>
                        <p className="ceo_desc">{data?.ceoletter?.position}</p>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default CeoLetter