import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
import PageBanner from "../../layout/page-banner/PageBanner";

import { useParams } from "react-router";

import './OurServiceDetails.scss';

import ChevronRight from "../../../../assets/icons/chevron-right.svg"
import Appoint from "../appoint/Appoint";

const SERVICEDETAILS = gql`
query GetServiceDetails($_locale: String!, $slug: String!){
    ourservices(where: {_locale: $_locale, slug: $slug}) {
        name,
        pageInnerTitle,
        slug,
        id,
        longDescription,
        subservices {
            name,
            shortDescription,
            slug,
            image {
                formats
            }
        },
        serviceIcon {
            formats
        }
    }
  }
`;

function OurServiceDetails() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [ourServices, setOurServices] = useState(loc.pathname.split('/')[1]);

    let { slug } = useParams();

    const { loading, error, data } = useQuery(SERVICEDETAILS, {
        variables: {
            _locale: lang,
            slug: slug
        },
        onCompleted(data) {
            console.log(data);
            // setOurServices(data);
        }
    });

    return (
        <div>
            <PageBanner name={data?.ourservices[0]?.name}
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            {/* <PageBanner name={data?.ourservices[0]?.name} description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" /> */}
            <div className="services container">
                <div className="services_heading">
                    <div className="services_avatar">
                        <img className="services_img" src={'https://dev-cpanel.knexel.com/' + data?.ourservices[0]?.serviceIcon?.formats?.medium?.url} alt="" />
                    </div>
                    <div className="services_jumbotron">
                        <h2 className="services_header">{data?.ourservices[0]?.pageInnerTitle}</h2>
                        <ReactMarkdown className="services_description">
                            {data?.ourservices[0]?.longDescription}
                        </ReactMarkdown>
                    </div>
                </div>
                <div className="mobile_navigation">
                    <img className="mobile_navigation_img left" src="assets/icons/navigating_left.svg" alt="" />
                    <img className="mobile_navigation_img" src="assets/icons/navigating_right.svg" alt="" />
                </div>
                <div className="services_list">

                {data?.ourservices[0]?.subservices?.map((subService, i) => (
                    <Link to={`/${lang}/sub-service-details/${subService.slug}`} className="services_card" key={subService.slug+i} >
                        <img className="services_card_img" src={'https://dev-cpanel.knexel.com/' + subService?.image?.formats?.medium?.url} alt="" />
                        {/*<div className="services_card_info">*/}
                        {/*    <p className="services_card_info_head">{subService.name}</p>*/}
                        {/*    <p className="services_card_info_desc">{subService.shortDescription}</p>*/}
                        {/*    <div className="circle_projects">*/}
                        {/*        <div className="chevron_circle_projects">*/}
                        {/*            <img width="12" height="12" src={ChevronRight} alt="" />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Link>
                ))}
                </div>
            </div>

                    <Appoint/>
        </div>

    )
}

export default OurServiceDetails
