import React, { useEffect, useState } from "react";
import {
    gql,
    useMutation
} from "@apollo/client";
import { useForm } from "react-hook-form";
import AppointBg from "../../../../assets/icons/appoint_bg.svg"
import CheckCircle from "../../../../assets/icons/check-circle.svg"

const CREATEAPPOINT = gql`
mutation CreateAppoiont ($fullname: String!, $email: String!, $phone: String!) {
    createAppoint(input:
        { data: {
            fullname: $fullname,
            email: $email,
            phone: $phone
        }}) {
            appoint {
                id
            }
        }
      }
`;

function Appoint() {
    const [formSubmitCond, setFormSubmitCond] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

    const [onMutationSubmit] = useMutation(CREATEAPPOINT, {
        onCompleted: data => {
            reset();
            setFormSubmitCond(true);
            setTimeout(() => {
                setFormSubmitCond(false);
            }, 2000);
        }
    });

    const onFormSubmit = (data) => {
        onMutationSubmit({
            variables: {
                fullname: data.fullname,
                email: data.email,
                phone: data.phone
            }
        })
    }

    return (
        <section id="appoint">
            {/* <div className="appoint" style="background: url('../../assets/images/appoint_bg.svg') no-repeat center;"> */}
            <div className="appoint" style={{ backgroundImage: `url(${AppointBg})` }}>
                <div className="appoint_content container">
                    <div className="appoint_info">
                        <h2 className="appoint_head">BİZDƏN GÖRÜŞ AL</h2>
                        <p className="appoint_desc">Texnologiya sahəsindəki 10 ildən çox təcrübəmiz, bir şirkətin bütün iş proseslərində yaranan ehtiyaclarını tam şəkildə anlamağa və həll yolu təqdim etməyə əsas verir.</p>
                    </div>
                    <form className="appoint_form" onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="form_name_email">
                            <input className={`form_name form_input ${errors.phone ? "error" : ""}`} type="text" placeholder="Ad, Soyad"
                                {...register("fullname", {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    }
                                })} />
                            <input className={`form_email form_input ${errors.phone ? "error" : ""}`} type="email" placeholder="Email"
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    },
                                    pattern: {
                                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                        message: 'Invalid Email'
                                    }
                                })} />
                        </div>
                        <div className="number_submit">
                            <input className={`form_number form_input ${errors.phone ? "error" : ""}`} type="text" placeholder="Nömrə"
                                {...register("phone", {
                                    required: {
                                        value: true,
                                        message: 'Required'
                                    }
                                })} />
                            <button className="submit">Göndər</button>
                        </div>
                        {formSubmitCond ?
                            <div className="suscces-msg">
                                <img src={CheckCircle} />
                                <p>Mesajınız uğurla göndərildi!</p>
                            </div>
                            : ''
                        }
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Appoint