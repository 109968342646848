import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
import PageBanner from "../../layout/page-banner/PageBanner";

import './CompanyFilosofy.scss';

const COMPANYFILOSOFY = gql`
query GetCompanyFilosofy{
    companyfilosofypage {
        filosofySection {
            title,
            description,
            image {
                formats,
                url
            }
        }
    }
  }
`;

function CompanyFilosofy() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    const { loading, error, data } = useQuery(COMPANYFILOSOFY);

    return (
        <div>
            <PageBanner name="ŞİRKƏTİN FƏLSƏFƏSİ"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div class="philosophy container">

                {data?.companyfilosofypage?.filosofySection?.map((section, i) => (
                    <>
                        {(i % 2 === 0) ?
                            <div class="philosophy_top">
                                <div class="desription">
                                    <h2 class="desc_head">{section?.title}</h2>
                                    <ReactMarkdown className="desc">
                                        {section?.description}
                                    </ReactMarkdown>
                                </div>
                                <div class="media">
                                    <img class="phy_media" src={'https://dev-cpanel.knexel.com/' + section?.image?.url} alt="" />
                                </div>
                            </div>
                            :
                            <div class="philosophy_bottom">
                                <div class="media bottom_media">
                                    <img class="phy_media " src={'https://dev-cpanel.knexel.com/' + section?.image?.url} alt="" />
                                </div>
                                <div class="desription desription_bottom">
                                    <h2 class="desc_head">{section?.title}</h2>
                                    <ReactMarkdown className="desc">
                                        {section?.description}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        }
                    </>
                ))}


                {/* <div class="philosophy_top">
                    <div class="desription">
                        <h2 class="desc_head">Köhnəlmiş sistemləri kənara qoyaraq texnologiya dünyasına daha yaxın olun.</h2>
                        <h2 class="desc">
                            Bir çox şirkətlər üçün texnologiya sahəsi, əsəb, stress və qeyri-müəyyənliklərdən ibarətdir. Biz inanırıq ki, şirkətlərdə bu düşüncəni dəyişmək üçün daha məntiqli və nəticə yönümlü həll yolları mövcuddur.
                            Texnologiya – hər gün ofisinizdə ayağınıza dolaşan kabellər deyil, hədəfə çatmağa təkan verən sistemli infrastrukturdur. Sizi narahat edən, işinizin axsamasına gətirib çıxaran problemlərin həlli üçün rahat və sadə həll yolları mövcuddur.
                        </h2>
                    </div>
                    <div class="media">
                        <img class="phy_media" src="assets/images/phylos_media.svg" alt=""/>
                    </div>
                </div>

                <div class="philosophy_bottom">
                    <div class="media bottom_media">
                        <img class="phy_media " src="assets/images/phylos_media.svg" alt=""/>
                    </div>
                    <div class="desription desription_bottom">
                        <h2 class="desc_head">Köhnəlmiş sistemləri kənara qoyaraq texnologiya dünyasına daha yaxın olun.</h2>
                        <h2 class="desc">
                            Bir çox şirkətlər üçün texnologiya sahəsi, əsəb, stress və qeyri-müəyyənliklərdən ibarətdir. Biz inanırıq ki, şirkətlərdə bu düşüncəni dəyişmək üçün daha məntiqli və nəticə yönümlü həll yolları mövcuddur.
                            Texnologiya – hər gün ofisinizdə ayağınıza dolaşan kabellər deyil, hədəfə çatmağa təkan verən sistemli infrastrukturdur. Sizi narahat edən, işinizin axsamasına gətirib çıxaran problemlərin həlli üçün rahat və sadə həll yolları mövcuddur.
                        </h2>
                    </div>
                </div> */}
            </div>

        </div>

    )
}

export default CompanyFilosofy