import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import { useParams } from "react-router";

import ReactMarkdown from "react-markdown"
import PageBanner from "../../../layout/page-banner/PageBanner";
import './VacancyDetails.scss';

const VACANCIES_DETAIL = gql`
query GetVacancies($slug: String!){
    vacancies(where: {slug: $slug}) {
        name
        slug,
        type,
        id,
        expireDate,
        experience,
        salary,
        responsibility,
        requirements,
        sendEmailDescription,
        note
    }
  }
`;

function VacancyDetails() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [vacancyDetails, setVacancyDetails] = useState(null);

    let { slug } = useParams();
    const { detailsLoading, detailsError, detailsData } = useQuery(VACANCIES_DETAIL, {
        variables: { slug },
        onCompleted: data => {
            console.log(data);
            setVacancyDetails(data.vacancies[0])
        }
    });

    return (
        <div>
            <PageBanner name={vacancyDetails?.name} description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="vacancy container">
                <div className="vacancy_head_part">
                    <div className="vacancy_headers">
                        <p className="vacancy_experience">İş Təcrübəsi</p>
                        <p className="vacancy_salary">Əmək haqqı</p>
                    </div>
                    <div className="vacancy_values">
                        <p className="experience_value">{vacancyDetails?.experience}</p>
                        <p className="salary_value">{vacancyDetails?.salary}</p>
                    </div>
                </div>
                <div className="vacancy_content">
                    <div className="vacancy_req">
                        <h2 className="req_head">Vəzifə və öhdəliklər:</h2>
                        <ReactMarkdown className="req_list">
                            {vacancyDetails?.responsibility}
                        </ReactMarkdown>
                    </div>
                    <div className="vacancy_req">
                        <h2 className="req_head">Namizəd üçün tələblər:</h2>
                        <ReactMarkdown className="req_list">
                            {vacancyDetails?.requirements}
                        </ReactMarkdown>
                    </div>
                </div>
                <p className="vacancy_footer">{vacancyDetails?.sendEmailDescription}</p>
                <p className="vacancy_footer">QEYD: {vacancyDetails?.note}</p>
            </div>
        </div>
    )
}

export default VacancyDetails