import React, { useEffect, useState } from "react";
import './Blogs.scss';
import { Link, useLocation } from "react-router-dom";
import chevronUpDark from '../../../../assets/icons/chevron-up-dark.svg';
import Project from '../../../../assets/images/project_card.svg';
import {
    useQuery,
    useLazyQuery,
    gql
} from "@apollo/client";
import axios from "axios";
import BlogCard from "./blog-card/BlogCard";
import PageBanner from "../../layout/page-banner/PageBanner";

const BLOGSNEWS = gql`
query GetBlogsNews($_newsBlogTypes: String, $_locale: String!,$_start: Int!,$_limit: Int!){
    blogsnews(where: {newsBlogTypes: $_newsBlogTypes, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        startDate
        slug,
        id,
        shortDesc,
        newsBlogTypes,
        pageMainContent{
            title,
            image {
                formats
            }
        }
    }
  }
`;


let totalBlogCount = 0;
let count = 0;
function Blogs() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [activeTab, setActiveTab] = useState('all');
    let [activeTabText, setActiveTabText] = useState('Hamısı');
    let [isMobileTabOpen, setIsMobileTabOpen] = useState(false);
    let [newsList, setNewsList] = useState([]);
    let [queryParams, setQueryParams] = useState(null);

    const [getBlogs, { loading, error, data }] = useLazyQuery(BLOGSNEWS, {
        variables: queryParams,
        onCompleted: data => {
            count += 20;
            setNewsList([...newsList, ...data.blogsnews])
        }
    });

    useEffect(() => {
        return () => {
            totalBlogCount = 0;
        };
    }, []);

    useEffect(() => {
        totalBlogCount = 0;
        if (totalBlogCount === 0) {
            const itemCountQuery = activeTab !== 'all' ?
            `https://dev-cpanel.knexel.com/blogsnews/count?newsBlogTypes=${activeTab}` :
            `https://dev-cpanel.knexel.com/blogsnews/count`
            axios(itemCountQuery).then(resp => {
                totalBlogCount = resp.data;
                setNewsList([]);
                count = 0;
                seeMore();
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if(queryParams?._locale){
            getBlogs();
        }
    }, [queryParams]);

    if (error) return <p>Error :(</p>;

    const seeMore = () => {
        let paramsData = {
            _locale: lang,
            _start: count,
            _limit: 20
        }
        if (activeTab !== 'all') {
            paramsData["_newsBlogTypes"] = activeTab;
        }
        setQueryParams({...paramsData});
        
    }

    const handleTabChange = (tab, isFromMobile = false) => {
        setActiveTab(tab);

        if(isFromMobile){
            setIsMobileTabOpen(false);
            setActiveTabText(isFromMobile);
        }
    }

    return (
        <div className="blogs">
            <PageBanner name="BLOQLAR VƏ XƏBƏRLƏR"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <section id="blogs" className="pb--120px">
                <div className="blogs_content container">
                    <div className="blogs_tab">
                        <button onClick={() => handleTabChange('all')} className={`tab_item ${activeTab === 'all' ? "selected_tab" : ""}`}>
                            Hamısı
                        </button>
                        <div className="tab_divider"></div>
                        <button onClick={() => handleTabChange('blog')} className={`tab_item ${activeTab === 'blog' ? "selected_tab" : ""}`}>Bloqlar</button>
                        <div className="tab_divider"></div>
                        <button onClick={() => handleTabChange('news')} className={`tab_item ${activeTab === 'news' ? "selected_tab" : ""}`}>Xəbərlər</button>
                    </div>
                    <div className="blogs_tab_mobile">
                        <button className="select_tab" onClick={()=>setIsMobileTabOpen(!isMobileTabOpen)}>
                            <p className="select_name">{activeTabText}</p>
                            <img className="menu_item_chevron" src={chevronUpDark} alt="" />
                        </button>
                        <div className={`tab_options ${isMobileTabOpen? 'active':''}`}>
                            <button onClick={() => handleTabChange('all', 'Hamısı')}>Hamısı</button>
                            <button onClick={() => handleTabChange('blog', 'Bloqlar')}>Bloqlar</button>
                            <button onClick={() => handleTabChange('news', 'Xəbərlər')}>Xəbərlər</button>
                        </div>
                    </div>
                    <div className="blogs_list">
                        {newsList?.map((blog, i) => (
                            <div className="new_card_wrapper" key={blog.slug + i}>
                                <BlogCard blog={blog} />
                            </div>
                        ))}
                    </div>

                    {newsList.length < totalBlogCount ?
                        <button className="action_button more" onClick={seeMore}>Daha çox</button>
                        : ''
                    }
                </div>

            </section>
        </div>
    )
}

export default Blogs
