import React from "react";
import { Link, useLocation } from "react-router-dom";
// import './Blogs.scss';

// import chevronUpDark from '../../../../assets/icons/chevron-up-dark.svg';
// import Project from '../../../../assets/images/project_card.svg';

function BlogCard(blog) {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];

    return (
        <Link to={`/${lang}/${blog.blog.newsBlogTypes}-details/${blog.blog.slug}`} className="new_card">
            {blog.blog.newsBlogTypes ?
                <div className="new_badge">
                    <div className="badge_view">
                        {blog.blog.newsBlogTypes}
                    </div>
                </div>
                : ''
            }
            <img className="card_img" src={'https://dev-cpanel.knexel.com/' + blog.blog.pageMainContent.image[0]?.formats?.medium?.url} alt="" />
            <div className="news_card_info">
                <p className="news_card_date">{blog.blog.startDate}</p>
                <p className="news_card_info_head">{blog.blog.pageMainContent.title}</p>
                <p className="news_card_info_desc">{blog.blog.shortDesc}</p>
                <p className="card_navigate">Daha ətraflı</p>
            </div>
        </Link>
    )
}

export default BlogCard