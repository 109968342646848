import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    useLazyQuery,
    gql
} from "@apollo/client";
import PageBanner from "../../layout/page-banner/PageBanner";
import axios from "axios";
import './Vacancies.scss';
import VacancyCard from "./vacancy-card/VacancyCard";

const VACANCIES = gql`
query GetVacancies($_type: String!, $_locale: String!,$_start: Int!,$_limit: Int!){
    vacancies(where: {type: $_type, _locale: $_locale, _start: $_start, _limit: $_limit}) {
        name
        slug,
        type,
        id,
        expireDate,
        experience,
        salary
    }
  }
`;

let totalVacancyCount = 0;
let count = 0;
function Vacancies() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);
    let [activeTab, setActiveTab] = useState('vacancy');
    let [vacancyList, setVacancyList] = useState([]);
    let [queryParams, setQueryParams] = useState(null);

    const [getBlogs, { loading, error, data }] = useLazyQuery(VACANCIES, {
        variables: queryParams,
        onCompleted: data => {
            count += 20;
            setVacancyList([...vacancyList, ...data.vacancies])
        }
    });

    useEffect(() => {
        return () => {
            totalVacancyCount = 0;
        };
    }, []);

    useEffect(() => {
        totalVacancyCount = 0;
        if (totalVacancyCount === 0) {
            const itemCountQuery = `https://dev-cpanel.knexel.com/vacancies/count?type=${activeTab}`;
            axios(itemCountQuery).then(resp => {
                totalVacancyCount = resp.data;
                setVacancyList([]);
                count = 0;
                seeMore();
            });
        }
    }, [activeTab]);

    useEffect(() => {
        if (queryParams?._locale) {
            getBlogs();
        }
    }, [queryParams]);

    if (error) return <p>Error :(</p>;

    const seeMore = () => {
        let paramsData = {
            _locale: lang,
            _start: count,
            _limit: 20,
            _type: activeTab
        }

        setQueryParams({ ...paramsData });
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div>
            <PageBanner name="VAKANSİYALAR"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />
            <div className="vacancies container">
                <div className="vacancies_tab">
                    <div onClick={() => handleTabChange('vacancy')} className={`tab ${activeTab === 'vacancy' ? "tab_selected" : "tab_unselected"}`}>
                        <p className={`tab_name ${activeTab === 'vacancy' ? "tab_selected_name" : ""}`}>Vakansiyalar</p>
                    </div>
                    <div onClick={() => handleTabChange('internship')} className={`tab ${activeTab === 'internship' ? "tab_selected" : "tab_unselected"}`}>
                        <p className={`tab_name ${activeTab === 'internship' ? "tab_selected_name" : ""}`}>Təcrübə proqramları</p>
                    </div>
                </div>
                <div className="vacancies_list">
                    {vacancyList?.map((vacancy, i) => (
                        <VacancyCard key={vacancy.slug + i} vacancy={vacancy} />
                    ))}
                </div>

                {vacancyList.length < totalVacancyCount ?
                    <button className="more action_button" onClick={seeMore}>Daha çox</button>
                    : ''
                }
            </div>
        </div>
    )
}

export default Vacancies