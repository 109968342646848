import React from "react";
import "./Footer.scss"
import logo from "../../../../assets/images/logo.svg";
import miniLogo from "../../../../assets/icons/knexel-footer-logo.svg";
import facebook from "../../../../assets/icons/facebook.svg";
import instgramsvg from "../../../../assets/icons/instgramsvg.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";
import location from "../../../../assets/icons/map-pin.svg";
import mail from "../../../../assets/icons/mail1.svg";
import phone from "../../../../assets/icons/phone1.svg";

import {
    Link,
    useLocation
} from "react-router-dom";

const Footer = () => {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];
    // const { loading, error, data } = useQuery(EXCHANGE_RATES1);

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error :(</p>;

    return (
        <footer>
            <div className="footer ">
                <div className="container footer_content">
                    <div className="knexel_info footer_col">
                        <img className="knexel_logo" src={logo} alt="" />
                        <p className="knexel_desc">İnnovativ İT həllərin köməyi ilə biznesinizin davamlılığını təmin edin.Məlumatlarınızın təhlükəsizliyindən əmin olun.Xərclərinizi optimallaşdırın.</p>
                        <div className="socials">
                            <a href="https://www.facebook.com/knexeltechnologies" target="_blank" rel="noopener noreferrer">
                                <img className="social_icons" src={facebook} alt="" />
                            </a>
                            <a href="https://www.instagram.com/knexeltechnologies/" target="_blank" rel="noopener noreferrer">
                                <img className="social_icons" src={instgramsvg} alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/knexeltechnologies" target="_blank" rel="noopener noreferrer">
                                <img className="social_icons" src={linkedin} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="footer_col d_none_mobile">
                        <p className="col_head">Xidmətlər</p>
                        <a className="col_navigate">Digital consulting</a>
                        <a className="col_navigate">Solution engineering</a>
                        <a className="col_navigate">Technology infrastructure</a>
                        <a className="col_navigate">IT Operations</a>
                    </div>
                    <div className="footer_col d_none_mobile" >
                        <p className="col_head">Faydalı linklər</p>
                        <Link to={`/${lang}/company-filosofy`} className="col_navigate">Haqqımızda</Link>
                        <Link  to={`/${lang}/projects`} className="col_navigate">Layihələr</Link>
                        <Link className="col_navigate" to={`/${lang}/blogs`}>Bloq</Link>
                        <Link className="col_navigate" to={`/${lang}/contact-us`}>Əlaqə</Link>
                        <Link className="col_navigate" to={`/${lang}/vacancies`}>Vakansiyalar</Link>
                    </div>
                    <div className="footer_col">
                        <p className="col_head">Əlaqə</p>
                        <a className="col_navigate contacts d-flex">   <img className="social_icons" src={location} alt="" /> H.Əliyev Pr. 125, Bakı</a>
                        <a href="#" className="col_navigate d-flex"><img className="social_icons" src={mail} alt="" />  office@knexel.com</a>
                        <a href="#" className="col_navigate d-flex"><img className="social_icons" src={phone} alt="" />  +994123105300</a>
                    </div>
                </div>
                <div className="container bottom">
                    <div className="footer_divider">
                        <hr className="hr" />
                        <img src={miniLogo} alt="" />
                        <hr className="hr" />
                    </div>
                    <div className="copyright">
                        <p className="copyright_info">© 2009-2022 Knexel Technologies. Bütün hüquqlar qorunur</p>
                        {/* <p className="copyright_info">Bu bir <a href="">Marcom</a> məhsuludur.</p> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
