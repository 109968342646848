import React from "react";
import "./PageBanner.scss"

import logoDark from "../../../../assets/images/st-bk.jpeg";


const PageBanner = (props) => {
    return (
        <div className="secondary_banner" style={{ backgroundImage: `url(${logoDark})` }} >
            <h2 className="sm_banner_h2"> {props.name}</h2>
            {/* <p className="sm_banner_p">{props.description}</p> */}
        </div>
    )
}

export default PageBanner