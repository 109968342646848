import React from "react";
import {
    Link
} from "react-router-dom";

import './NotFound.scss';

import notFound from '../../../../../assets/images/404.jpg';

function NotFound() {
    return (
        <div className="not_found">
            <img src={notFound} alt="" />
            <h1 className ="not_found_head">Bu səhİfə tapılmadı</h1>
            <p className ="not_found_desc">Axtardığınız səhifə tapılmadı.Silinib və ya dəyişdirilib.</p>
            {/* <button className ="action_button"> */}
                <Link to="/" className ="action_button">Əsas səhifə</Link>
                
            {/* </button> */}
        </div>

    )
}

export default NotFound