import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    useQuery,
    gql
} from "@apollo/client";
import ReactMarkdown from "react-markdown"
import PageBanner from "../../layout/page-banner/PageBanner";

import './Goals.scss';

import rightArrow from "../../../../assets/icons/vector.svg"

const CEOLETTER = gql`
query GetCeoLetter{
    goal {
        goalSection{
            wrapperName,
            listItem{
                title,
                description
            }
        }
    }
  }
`;

function Goals() {
    let loc = useLocation();
    let [lang, setLang] = useState(loc.pathname.split('/')[1]);

    const { loading, error, data } = useQuery(CEOLETTER);

    return (
        <div>
            <PageBanner name="MƏQSƏD VƏ DƏYƏRLƏR"
                imgUrl="url(https://images.unsplash.com/photo-1508919801845-fc2ae1bc2a28?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW1nfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80)" />

            {data?.goal?.goalSection?.map((section, i) => (
                <div className={`goals ${i%2===0 ? "" : "values"}`} key={section.wrapperName + i}>
                    <div className="container">
                        <div className="goals_head">
                            <img className="right_log" src={rightArrow} alt="" />
                            <h2 className="goals_header">{section.wrapperName}</h2>
                        </div>
                        <div className="goals_content">
                            <div className="goals_top">
                            {section?.listItem?.map((card, i) => (
                                <div className="top_left" key={card.title + i}>
                                    <div className="content_head">
                                        <h3 className="content_header">{card.title}</h3>
                                    </div>
                                    <p className="content_data">{card.description}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* <div className="goals values">
                
            </div> */}
        </div>

    )
}

export default Goals