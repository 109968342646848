import React from "react";
import { Link, useLocation } from "react-router-dom";
// import './Blogs.scss';

// import chevronUpDark from '../../../../assets/icons/chevron-up-dark.svg';
// import Project from '../../../../assets/images/project_card.svg';

function VacancyCard(vacancy) {
    let loc = useLocation();
    const lang = loc.pathname.split('/')[1];

    return (
        <Link to={`/${lang}/${vacancy.vacancy.type}-details/${vacancy.vacancy.slug}`} className="vacancy_item">
            <p className="vacancy_name">{vacancy.vacancy.name}</p>
            <hr className="vacancy_divider" />
            <div className="vacancy_data_names">
                <p className="vacancy_data_name">İş Təcrübəsi</p>
                <p className="vacancy_data_name">Əmək haqqı</p>
            </div>
            <div className="vacancy_data_names">
                <p className="vacancy_data_value">{vacancy.vacancy.experience}</p>
                <p className="vacancy_data_value">{vacancy.vacancy.salary} AZN</p>
            </div>
            <hr className="vacancy_divider" />
            <div className="vacancy_data_names">
                <p className="vacancy_date">Bitmə tarixi: {vacancy.vacancy.expireDate}</p>
                {vacancy.vacancy.type} {vacancy.vacancy.slug}
                <p className="vacancy_navigate">Ətraflı</p>
                {/* <Link to={`/${lang}/${blog.blog.newsBlogTypes}-details/${blog.blog.slug}`} className="card_navigate">Daha ətraflı</Link> */}
            </div>
        </Link>
    )
}

export default VacancyCard